<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col">
            <h5 class="mb-0">
              <i class="far fa-hat-chef me-2"></i>The Kitchen Board
            </h5>
          </div>

          <div class="col-auto ms-auto">
            <router-link
              class="btn btn-outline-success btn-sm ms-2"
              to="/kitchen/board"
              >Board</router-link
            >
            <router-link
              class="btn btn-outline-success btn-sm ms-2"
              to="/kitchen/allergies"
              >Allergies</router-link
            >
            <router-link
              class="btn btn-outline-success btn-sm ms-2"
              to="/kitchen/supplements"
              >Supplements</router-link
            >
          </div>
        </div>

        <!--  -->

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Client Name</th>
              <th scope="col">Juicing</th>
              <th scope="col">Allergies</th>
              <th scope="col">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="i in intakes"
              :key="i.id"
              :class="i.allergies ? 'bg-warning text-dark ' : ''"
            >
              <td>{{ i.client ? i.client.name : "-" }}</td>
              <td>{{ i.juice_type }}</td>
              <td>{{ i.allergies }}</td>
              <td>{{ i.kitchen_notes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      intakes: [],
    };
  },
  methods: {
    fetchIntakes() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/intake-forms")
        .then(({ data }) => {
          this.intakes = data.data;
        });
    },
  },
  beforeDestroy() {
    this.$store.sidebarOpen = true;
  },
  mounted() {
    this.fetchIntakes();
    this.$store.sidebarOpen = false;
  },
};
</script>

<style>
</style>